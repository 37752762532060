import request from '@/utils/request'

// 查询掌榕网的用户信息
export function getAccount(id) {
  return request({
    url: '/sms/sms-handle/get-account?id=' + id,
    method: 'get'
  })
}


// 查询掌榕网的短信签名查询接口
export function getSignList(id) {
  return request({
    url: '/sms/sms-handle/get-sign-list?id=' + id,
    method: 'get'
  })
}


// 创建掌榕网的短信签名接口
export function createSign(data) {
  console.log(data)
  return request({
    url: '/sms/sms-handle/create-sign',
    method: 'post',
    data: data
  })
}

// 修改掌榕网的短信签名接口
export function updateSign(id,signId,sign,description) {
  return request({
    url: '/sms/sms-handle/update-sign?id=' + id + '&signId='+signId + '&sign='+sign +'&description='+description,
    method: 'get'
  })
}
// 修改掌榕网的短信签名接口
export function delSign(id,signId) {
  return request({
    url: '/sms/sms-handle/del-sign?id=' + id + '&signId='+signId,
    method: 'get'
  })
}

// 查询掌榕网的短信模板
export function getTemplateList(id) {
  return request({
    url: '/sms/sms-handle/get-template-List?id=' + id,
    method: 'get'
  })
}
// 查询掌榕网的短信模板
export function getTemplate(id,templateId) {
  return request({
    url: '/sms/sms-handle/get-template?id=' + id + '&templateId='+templateId,
    method: 'get'
  })
}


// 查询掌榕网的短信模板
export function createTemplate(data) {
  return request({
    url: '/sms/sms-handle/create-template',
    method: 'post',
    data: data
  })
}



// 查询掌榕网的短信模板
export function updateTemplate(data) {
  return request({
    url: '/sms/sms-handle/update-template',
    method: 'post',
    data: data
  })
}

// 查询掌榕网的短信模板
export function synTemplate(id,templateId) {
  return request({
    url: '/sms/sms-handle/syn-template?id=' + id + '&templateId='+templateId,
    method: 'get'
  })
}

// 查询掌榕网的短信模板
export function delTemplate(id,templateId) {
  return request({
    url: '/sms/sms-handle/del-template?id=' + id + '&templateId='+templateId,
    method: 'get'
  })
}

// 创建掌榕网的短信账户
export function crateAccount(data) {
  return request({
    url: '/sms/sms-handle/create-account',
    method: 'post',
    data: data
  })
}


// 创建掌榕网的短信账户
export function getAccountInfo() {
  return request({
    url: '/sms/sms-handle/get-account-info',
    method: 'get',
  })
}


// 查询掌榕网的短信套餐
export function getSmsSetMeal() {
  return request({
    url: '/sms/sms-handle/get-sms-set-meal',
    method: 'get'
  })
}

// 创建掌榕网的短信套餐订单
export function createSmsSetMealOrder(channelId,packageId,money) {
  return request({
    url: '/sms/sms-handle/create-sms-set-meal-order?id='+channelId +"&packageId="+packageId +"&money="+money ,
    method: 'get'
  })
}



// 查询掌榕网的短信套餐
export function getSmsSetMealOrder(orderId) {
  return request({
    url: '/sms/sms-handle/get-sms-set-meal-order?orderId='+orderId ,
    method: 'get'
  })
}

